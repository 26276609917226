<template>
    <div class="container" :loading="loading">
        <div class="row no-gutters center" style="justify-content:center">
            <div class="h2 font-weight-bold pt-2 pb-2 p-sm-0 pb-sm-3 elipssis center">
                <a style="justify-content:center" :href="event.url">{{eventEdit.title}}</a>
            </div>
        </div>       
        <div class="row no-gutters">
            <div class="col image-cover">
                <el-image :src="event.imageSrc">
                    <template #error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>                
            </div>
        </div>   
        <div class="row" >
            <div class="col-12" style="justify-content:center">{{eventEdit.description}}</div>
        </div>
        <hr />
        <div class="row">
            <div class="col-12">
                <label class="top-label">Fundraiser Information</label>
                <div class="title">
                    <div style="overflow: hidden; ">
                        <div class="row">
                            <div class="col-4 font-weight-bold"><h6 class="font-weight-bold">Fundraiser Title:</h6></div>
                            <div class="col-8 text-left"><h6>{{eventEdit.title}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-bold"><h6 class="font-weight-bold">Fundraiser Description:</h6></div>
                            <div class="col-8 text-left"><h6>{{eventEdit.description}}</h6></div>
                        </div>
                        <!--<div class="row">
                            <div class="col-4 font-weight-bold"><h6 class="font-weight-bold">Fundraiser Code:</h6></div>
                            <span class="col-8 text-left" id="eventCode" :data-copy="event.url"><h6>{{event.code}}</h6></span>
                        </div>-->
                        <div class="row">
                            <div class="col-4 font-weight-bold"><h6 class="font-weight-bold">Fundraiser Organizer Email:</h6></div>
                            <span class="col-8 text-left" ><h6>{{profile.email}}</h6></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <label class="top-label">Fundraiser Statistics</label>
                <div class="title">
                    <div style="overflow: hidden; ">
                        <div class="row">
                            <div class="col-4"><h6 class="font-weight-bold">START DATE:</h6></div>
                            <div class="col-8"><h6>{{new Date(event.startDate).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4"><h6 class="font-weight-bold">END DATE:</h6></div>
                            <div class="col-8"><h6>{{new Date(event.endDate).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</h6></div>
                        </div>
                        <hr />
                        <div class="statistics">
                            <div class="row">
                                <div class="col-4"><h6 class="font-weight-bold">Fundraiser Target:</h6></div>
                                <div class="col-8 text-left"><h6>${{event.targetAmount}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6>Total Sales:</h6></div>
                                <div class="col-8 text-left"><h6>${{ parseFloat(event.allStoresSubTotal).toFixed(2) }}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6>Total Popcorn Earnings (50%):</h6></div>
                                <div class="col-8 text-left"><h6 style="color:green">${{ totalPopcornSalesNet }}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6>Total Direct Donations (After Fees):</h6></div>
                                <div class="col-8 text-left"><h6 style="color:green">${{ totalDirectDonationsNet }}</h6></div>
                            </div>                          

                            <div class="row">
                                <div class="col-4"><h6><strong>Total Fundraiser Earnings:</strong></h6></div>
                                <div class="col-8 text-left">
                                    <el-button class="earnings-button"
                                               type="primary"
                                               size="medium"
                                               @click="openSalesBreakdown"
                                               title="Click to view fundraiser earnings breakdown">
                                        💰 Total Profit: ${{ parseFloat(totalEarnedProfits).toFixed(2) }}
                                    </el-button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6 class="">Number of Participants:</h6></div>
                                <div class="col-8 text-left"><h6>{{event.participantsCount}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6 class="">Number of Donations:</h6></div>
                                <div class="col-8 text-left"><h6>{{event.donationsCount}}</h6></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <el-dialog title="Fundraiser Sales Breakdown"
                       v-model="showSalesBreakdown"
                       :width="dialogWidth"
                       :top="dialogTop">

                <el-button type="primary" @click="downloadCSV">Download CSV</el-button>

                <el-table :data="safeEventOrderDetails" class="small-font-table" style="width:100%" height="350">
                    <el-table-column prop="createdAt" label="Order Date" width="150" :formatter="formatDate" />
                    <el-table-column prop="number" label="Order Number" width="175" />
                    <el-table-column prop="buyerName" label="Buyer Name" width="150" />
                    <!--<el-table-column prop="status" label="Order Status" width="150" />-->
                    <el-table-column prop="seller" label="Seller Name" width="150" />
                    <el-table-column label="Popcorn Sales" width="160" :formatter="calculatePopcornAmount" />
                    <el-table-column prop="directDonationAmount" label="Direct Donations" width="150" :formatter="formatCurrency" />
                    <!--<el-table-column prop="shippingFee" label="Shipping Fee" width="150" :formatter="formatCurrency" />-->
                    <el-table-column prop="directDonationFee" label="Direct Donation Fee" width="185" :formatter="formatCurrency" />
                    <el-table-column prop="subTotal" label="Order Subtotal" width="150" :formatter="formatCurrency" />
                    <el-table-column prop="total" label="Order Total" width="150" :formatter="formatCurrency" />
                    <el-table-column label="Order Earnings" width="150" :formatter="calculateEarnings" />
                </el-table>                

                <!-- Summary Section -->
                <hr />
                <div class="center">
                    <div class="row">
                        <div class="col-6"><h6>Total Popcorn Sales (Net):</h6></div>
                        <div class="col-6 text-left"><h6 style="color:green">${{ totalPopcornSalesNet }}</h6></div>
                    </div>
                    <div class="row">
                        <div class="col-6"><h6>Total Direct Donations (Net):</h6></div>
                        <div class="col-6 text-left"><h6 style="color:green">${{ totalDirectDonationsNet }}</h6></div>
                    </div>
                    <div class="row">
                        <div class="col-6"><h6><strong>Total Net Earnings:</strong></h6></div>
                        <div class="col-6 text-left"><h6 style="color:green"><strong>${{ totalNetEarnings }}</strong></h6></div>
                    </div>
                </div>


                <!-- Summary Section -->
                <hr />
                <div class="fundraiser-earnings-explainer">
                    <h6>📊 How Fundraiser Earnings Are Calculated:</h6>
                    <ul>
                        <li><strong>Popcorn Sales:</strong> 50% of total popcorn sales go directly to the fundraiser.</li>
                        <li><strong>Direct Donations:</strong> 90% of each donation goes to the fundraiser, with a 10% fee deducted to cover credit card processing, hosting, website maintenance, and operational costs.</li>
                    </ul>
                    <p><strong>Final Formula:</strong></p>
                    <p>
                        <code>Total Earnings = (Popcorn Sales / 2) + (Direct Donations × 90%)</code>
                    </p>
                </div>

                <template #footer>
                    <el-button @click="showSalesBreakdown = false">Close</el-button>
                </template>
            </el-dialog>


            <el-dialog title="TOTAL PROFITS" style="font-weight:bold" :width="dialogWidth" :top="dialogTop" v-model="eventBreakDown" lock-scroll="false">
                <div class="">
                    <el-form :model="calculatedTotals" show-message="false" @submit.prevent>
                        <div class="row no-gutters pb-2 h5">
                            <div class="col-12">
                                <div class="word-line-break mt-3"><span>BREAKDOWN</span></div>
                                <div class="row">
                                    <div class="col-9"><h6>Total Sales</h6></div>
                                    <div class="col-3 text-left"><h6>${{parseFloat(event.allStoresSubTotal).toFixed(2)}}</h6></div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-9"><h6>Total Earned Profits(50% of Fundraiser Subtotal):</h6></div>
                                    <div class="col-3 text-left"><h6 style="color: green">${{parseFloat(event.allStoresSubTotal / 2).toFixed(2)}}</h6></div>
                                </div>
                                <div class="word-line-break mt-3"><span>SALES</span></div>
                                <div class="donators">
                                    <el-table :data="donatorsOrders" style="width:100%" height="350">
                                        <el-table-column prop="orderdate" label="ORDER DATE" width="150" />
                                        <el-table-column prop="ordernum" label="ORDER NUMBER" width="150" />
                                        <el-table-column prop="name" label="BUYER NAME" width="150" />
                                        <el-table-column prop="amount" label="AMOUNT" width="150" />
                                        <el-table-column prop="participantname" label="SELLER NAME" width="150" />
                                    </el-table>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="eventBreakDown=false">Close</el-button>
                    </span>
                </template>
            </el-dialog>
            <div class="col-12 col-lg-6" v-if="profile!=null&&!editMode&&this.event.status=='Ended'&&this.event.organizerStoreSubTotal>0">
                <label class="top-label" style="color: #9A3324">Fundraiser Payout Information</label>
                <div class="title">
                    <div class="col-8 text-left">
                        <h6 class="font-weight-bold" style="word-break:keep-all;">To get started with your funraiser payout, go to your Profile>settings>Payment Methods</h6>
                        <div class=""><h6><router-link style="color: #9A3324" title="Go to Settings>Payment Methods" :to="{ name: 'Settings' }">Settings>PaymentMethods</router-link></h6></div>
                    </div>
                </div>
            </div>
        </div>       

      
       
    </div>
</template>

<script>
    import axios from "axios"
    import { copyToClipboard } from "../assets/js/app.js"; 
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import { mapGetters } from "vuex"
    import { initDwollaSdk, } from '@/services';

    export default {
        name: "EventDetail",
        //components: { PieChart, DoughnutChart },
        data() {
            return {
                calculatedTotals: {
                    totalSales: 0,
                    eventSubtotal: 0,
                    totalElgibleProfit: 0,
                    shippingCosts: 0,
                    adminFee: 0,
                },
                eventBreakDown: false,
                errorMessage: '',
                event: {},
                eventorderdetails: {},
                eventEdit: {
                    imageCropData: {}
                },
                file: null,
                loading: false,
                editMode: false,
                accounttypes: [
                    { name: "Checking", id: "Checking" },
                    { name: "Savings", id: "Savings" }
                ],
                showSalesBreakdown: false,
                showACH: false,
                consent: false,
                terms: false,
                termsDialogVisible: false,
                profile: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    type: 'personal',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    postalCode: '',
                    dateOfBirth: '',
                    ssn: '',
                    accountType:'',
                    accountNumber: '',
                    routingNumber: '',
                    accountName: '',
                    customerVerfiedStatus: '',
                    fundingSrcLinkStatus: '',
                    microDepositsStatus: '',
                    verifyMicroDepositsStatus: '',
                    amount1: null,
                    amount2: null,
                    transactionHistory: []
                },
                profileRules: {
                    firstName: [
                        { required: true, message: 'First Name is required', trigger: 'blur' }
                    ],
                    LastName: [
                        { required: true, message: 'Last Name is required', trigger: 'blur' }
                    ],
                    email: [
                        { required: true, message: 'email is required', trigger: 'blur' }
                    ],
                    accountName: [
                        { required: true, message: 'Account Name is required', trigger: 'blur' }
                    ],
                    accountNumber: [
                        { required: true, type: 'string', message: 'Account Number is required', trigger: 'blur' }
                    ],
                    routingNumber: [
                        { required: true, type: 'string', pattern: /^(\d{3,9})$/g, message: 'Routing Number is required and should be 9 digits long', trigger: 'blur' }
                    ],
                    accountType: [
                        { required: true, message: 'Account Type is required', trigger: 'blur' }
                    ],                   
                    //ssn: [
                    //    //{ required: true, type: 'string', pattern: /^(? !0{ 4}) \\d{ 4}$/g, message: 'Last four digits of SSN is required from 0001-9999', trigger: 'blur' }
                    //    { required: true, message: 'Last four digits of SSN is required from 0001-9999', trigger: 'blur' }
                    //]
                },
                profileRules2: {
                    amount1: [
                        { required: true, type: 'string', pattern: /^[0-9]*(\.[0-9]{1,4})?$/g, message: 'Amount 1 is required', trigger: 'blur' }
                    ],
                    amount2: [
                        { required: true, type: 'string', pattern: /^[0-9]*(\.[0-9]{1,4})?$/g, message: 'Amount 2 is required', trigger: 'blur' }
                    ],                   
                },
                chartOptions: {
                    responsive: true,
                    animation: {
                        duration: 0
                    },
                    hover: {
                        animationDiration: 0
                    },
                    responsiveAnimationDuration: 0,
                    plugins: {
                        ChartDataLabels: {
                            labels: [
                                {
                                    render: 'label',
                                    position: 'outside'
                                },
                                {
                                    render: 'percentage'
                                }
                            ]
                        }
                    }
                },
            }
        },
        computed: {
            // Total Popcorn Sales (Net) - 50% of total popcorn sales
            totalPopcornSalesNet() {
                if (!this.eventorderdetails || !Array.isArray(this.eventorderdetails)) return 0;

                return this.eventorderdetails.reduce((sum, order) => {
                    const popcornSales = (parseFloat(order.subTotal) || 0) - (parseFloat(order.directDonationAmount) || 0);
                    return sum + (popcornSales / 2); // 50% of popcorn sales
                }, 0).toFixed(2);
            },
            // 🟢 **Total Direct Donations (Net)** - Direct donations minus **10% fees** (always)
            totalDirectDonationsNet() {
                if (!this.eventorderdetails || !Array.isArray(this.eventorderdetails)) return 0;

                return this.eventorderdetails.reduce((sum, order) => {
                    let directDonation = parseFloat(order.directDonationAmount) || 0;
                    const directDonationFee = directDonation * 0.10; // Always take 10%

                    return sum + (directDonation - directDonationFee); // Net donation amount
                }, 0).toFixed(2);
            },
            // 🟢 **Total Net Earnings** = (Popcorn Earnings) + (Net Direct Donations)
            totalNetEarnings() {
                return (parseFloat(this.totalPopcornSalesNet) + parseFloat(this.totalDirectDonationsNet)).toFixed(2);
            },
            // 🟢 **Gross Sales** = Total of all orders
            grossSales() {
                if (!this.eventorderdetails || !Array.isArray(this.eventorderdetails)) return 0;

                return this.eventorderdetails.reduce((sum, order) => {
                    if (!order || typeof order !== "object") return sum; // Skip invalid orders

                    const orderTotal = parseFloat(order.total) || 0; // Ensure total is a number
                    return sum + orderTotal;
                }, 0).toFixed(2);
            },
            // 🟢 **Total Net Sales** = Sum of all order subtotals (excluding shipping)
            totalNetSales() {
                if (!this.eventorderdetails || !Array.isArray(this.eventorderdetails)) return 0;

                return this.eventorderdetails.reduce((sum, order) => {
                    if (!order || typeof order !== "object") return sum; // Skip invalid orders

                    const subTotal = parseFloat(order.subTotal) || 0; // Ensure subTotal is a number
                    return sum + subTotal;
                }, 0).toFixed(2);
            },
            // 🟢 **Total Earned Profits** = Sum of all store earnings
            totalEarnedProfits() {
                if (!this.participants || this.participants.length === 0) return 0;

                return this.participants.reduce((sum, participant) => {
                    return sum + this.calculateEarningsPerStore(participant);
                }, 0).toFixed(2);
            },
            safeEventOrderDetails() {
                return Array.isArray(this.eventorderdetails) ? this.eventorderdetails : [];
            },
            dialogWidth() {
                return screen.width <= 576 ? "100%" : "80%";
            },
            dialogTop() {
                return screen.width <= 576 ? "0" : "15vh";
            },
            screenWidth() {
                return screen.width;
            },
            participants() {
                let ps = [];
                if (this.event.participants != null) {
                    this.event.participants.split(',').forEach(p => {
                        let items = p.split('_');
                        ps.push({ name: items[0], targetAmount: items[1], amount: items[2] });
                    });
                }
                return ps;
            },
            donators() {
                let dn = [];
                if (this.event.donators != null) {
                    this.event.donators.split(',').forEach(p => {
                        let items = p.split('_');
                        dn.push({ name: items[0], amount: items[1] });
                    });
                }
                return dn;
            },
            donatorsOrders() {
                let dn = [];
                if (this.event.donatorsOrders != null) {
                    this.event.donatorsOrders.split(',').forEach(p => {
                        let items = p.split('_');
                        dn.push({ name: items[0], amount: items[1], ordernum: items[2], orderdate: items[3], participantname: items[4] });
                    });
                }
                return dn;
            },
            eventChartData() {
                let datatemplate = {
                    labels: [], datasets: [{ label: 'Event Totals', data: [], backgroundColor: [] }]
                };
                if (this.event != null) {
                    let percentage = ((this.event.allStoresSubTotal * 100) / this.event.targetAmount);
                    datatemplate.labels.push('All Participant Stores Goal: $' + this.event.targetAmount);
                    datatemplate.datasets[0].data.push(100 - Math.round(percentage));
                    datatemplate.datasets[0].backgroundColor.push('#E46651');

                    datatemplate.labels.push('Fundraiser Dollars raised: $' + this.event.allStoresSubTotal);
                    datatemplate.datasets[0].data.push(Math.round(percentage));
                    datatemplate.datasets[0].backgroundColor.push('#41B883');
                }
                return datatemplate;
            },
            participantsChartData() {
                let datatemplate = {
                    labels: [], datasets: [{ data: [], backgroundColor: [] }]
                };
                if (this.event.participants != null) {
                    this.event.participants.split(',').forEach(p => {
                        if (p != null) {
                            let items = p.split('_');
                            if (items != null) {
                                var color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
                                datatemplate.labels.push(items[0].trim());
                                datatemplate.datasets[0].data.push(items[2]);
                                datatemplate.datasets[0].backgroundColor.push(color);
                            }
                        }
                    });
                }
                return datatemplate;
            },
            donatorsChartData() {
                let datatemplate = {
                    labels: [], datasets: [{ data: [], backgroundColor: [] }]
                };
                if (this.event.donators != null) {
                    this.event.donators.split(',').forEach(p => {
                        if (p != null) {
                            let items = p.split('_');
                            if (items != null) {
                                var color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
                                datatemplate.labels.push(items[0].trim());
                                datatemplate.datasets[0].data.push(items[1].toString());
                                datatemplate.datasets[0].backgroundColor.push(color);
                            }
                        }
                    });
                }
                return datatemplate;
            },
            ...mapGetters(['currentUserDetail'])
        },
        mounted() {
                     
        },
        created() {
            this.getEventDetails();
            //this.getDwollaDetails();

            this.profile.email = this.currentUserDetail.email;
            this.profile.firstName = this.currentUserDetail.firstName;
            this.profile.lastName = this.currentUserDetail.lastName;
         

        },        
        methods: {
            calculateEarningsPerStore(participant) {
                if (!participant || typeof participant !== "object") return 0;

                // Ensure eventorderdetails is an array
                if (!Array.isArray(this.eventorderdetails)) return 0;

                // Find all orders that belong to this seller (participant)
                const sellerOrders = this.eventorderdetails.filter(order => order.seller === participant.name);

                if (sellerOrders.length === 0) return 0; // No matching orders

                // Aggregate order details
                const subTotal = sellerOrders.reduce((sum, order) => sum + (parseFloat(order.subTotal) || 0), 0);
                let directDonationAmount = sellerOrders.reduce((sum, order) => sum + (parseFloat(order.directDonationAmount) || 0), 0);

                // Calculate direct donation fees (10% of total direct donations)
                const directDonationFee = directDonationAmount * 0.10;

                // Calculate net donation amount after fee deduction
                const netDirectDonations = directDonationAmount - directDonationFee;

                // Popcorn sales (Subtotal minus Direct Donations)
                const popcornAmount = subTotal - directDonationAmount;

                // Popcorn Earnings (50% of Popcorn Sales)
                const popcornEarnings = popcornAmount / 2;

                // Final earnings calculation
                const totalEarnings = popcornEarnings + netDirectDonations;

                console.log(`Earnings for ${participant.name}: Popcorn (${popcornEarnings}) + Net Donations (${netDirectDonations}) = ${totalEarnings}`);

                return isNaN(totalEarnings) ? 0 : totalEarnings;
            },
            openSalesBreakdown() {
                if (!Array.isArray(this.eventorderdetails) || this.eventorderdetails.length === 0) {
                    console.error("No sales data available!");
                    return;
                }
                this.showSalesBreakdown = true;
            },
            formatDate(_, __, date) {
                if (!date) return "";
                const d = new Date(date);
                return isNaN(d.getTime()) ? "" : d.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
            },
            calculatePopcornAmount(row) {
                if (!row || typeof row !== "object") return "$0.00";
                const subTotal = parseFloat(row.subTotal) || 0;
                const directDonationAmount = parseFloat(row.directDonationAmount) || 0;
                return this.formatCurrency(null, null, subTotal - directDonationAmount);
            },           
            calculateEarnings(row) {
                if (!row || typeof row !== "object") return 0;

                // Ensure values are valid numbers
                const subTotal = parseFloat(row.subTotal) || 0;
                let directDonationAmount = parseFloat(row.directDonationAmount) || 0;
                const directDonationFee = parseFloat(row.directDonationFee) || 0; // New column

                // 1. Calculate Popcorn Earnings (50% of Popcorn Sales)
                const popcornEarnings = (subTotal - directDonationAmount) / 2;

                // 2. Deduct 10% from Direct Donations
                const netDirectDonations = directDonationAmount - directDonationFee;

                // 3. Final Fundraiser Earnings Calculation
                const totalEarnings = popcornEarnings + netDirectDonations;

                console.log(`Order Earnings Calculation: Popcorn (${popcornEarnings}) + Net Donations (${netDirectDonations}) = ${totalEarnings}`);

                // Ensure a valid currency format
                return isNaN(totalEarnings) ? 0 : totalEarnings;
            },
            formatCurrency(_, __, value) {
                if (value == null || isNaN(value)) return "$0.00";
                return new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(value);
            },
            downloadCSV() {
                if (!Array.isArray(this.eventorderdetails) || this.eventorderdetails.length === 0) {
                    console.error("No event order details available!");
                    return;
                }

                console.log("Downloading CSV...");

                const headers = [
                    "ORDER DATE", "ORDER NUMBER", "BUYER NAME", "SELLER NAME",
                    "POPCORN SALES", "DIRECT DONATION SALES", "DIRECT DONATION FEE",
                    "SHIPPING FEES", "SUBTOTAL", "ORDER TOTAL", "ORDER EARNINGS"
                ];

                const csvRows = this.eventorderdetails.map(row => {
                    console.log("Row data:", row); // Debug each row

                    return [
                        this.formatDate(null, null, row.createdAt),
                        row.number,
                        row.buyerName,
                        row.seller,
                        this.calculatePopcornAmount(row),
                        this.formatCurrency(null, null, row.directDonationAmount),
                        this.formatCurrency(null, null, row.directDonationFee),
                        this.formatCurrency(null, null, row.shippingFee),                       
                        this.formatCurrency(null, null, row.subTotal),
                        this.formatCurrency(null, null, row.total),
                        this.calculateEarnings(row)
                    ].map(value => {
                        if (value == null) return ""; // Prevent null errors
                        return `"${String(value).replace(/"/g, '""')}"`; // Escape quotes
                    }).join(",");
                });

                const csvContent = [headers.join(","), ...csvRows].join("\n");

                console.log("Generated CSV:", csvContent); // Debug the final CSV

                // Create and trigger a download link
                const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", "fundraiser_sales.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            calcTotals() {
                this.calculatedTotals.totalSales = this.event.organizerStoreTotal;
                this.calculatedTotals.shippingCosts = parseFloat(this.event.organizerStoreTotal - this.event.organizerStoreSubTotal).toFixed(2);
                this.calculatedTotals.eventSubtotal = parseFloat(this.event.organizerStoreSubTotal - (this.event.organizerStoreTotal * .035)).toFixed(2);
                this.calculatedTotals.adminFee = parseFloat(this.event.organizerStoreTotal * .035).toFixed(2);
                this.calculatedTotals.totalElgibleProfit = parseFloat((this.event.organizerStoreSubTotal - (this.event.organizerStoreTotal * .035)) / 2).toFixed(2);
            },
            termscheckbox(val) {
                this.terms = val;
            },
            changecheckbox(val) {
                this.consent = val;
            },           
            async getEventDetails() {
                await axios.get('/api/events/detail/' + this.$route.params.id).then(response => {
                    this.event = response.data;
                    this.eventEdit.id = response.data.id;
                    this.eventEdit.title = this.event.title;
                    this.eventEdit.description = this.event.description;
                    this.calcTotals();


                    axios.get('/api/organizers/eventorderdetails?eventId=' + response.data.id).then(response => {
                        console.log("Event Order Details API Response:", response.data);

                        // Ensure response.data is an array before assigning it
                        if (Array.isArray(response.data)) {
                            this.eventorderdetails = response.data;
                        } else {
                            console.error("eventorderdetails is not an array!", response.data);
                            this.eventorderdetails = []; // Fallback to empty array
                        }

                        this.loading = false;
                    }).catch((error) => {
                        console.error("Error fetching eventorderdetails:", error);
                        this.loading = false;
                    });
                }).catch((error) => {

                });
            },
            async getAddress() {
                await axios.get("/api/users/getaddress").then(response => {
                    this.profile.address1 = response.data.addressLine1;
                    this.profile.address2 = response.data.addressLine2;
                    this.profile.city = response.data.city;
                    this.profile.state = response.data.state;
                    this.profile.postalCode = response.data.zipCode;
                    //if (this.profile.address.country == null)
                    //    this.editMode = true;
                }).catch((error) => {
                });
            },
            shareOnFacebook() {
                window.open("https://www.facebook.com/sharer/sharer.php?u=" + this.event.publicUrl, "_blank");
            },
            shareOnTwitter() {
                window.open("https://twitter.com/share?url=" + this.event.publicUrl, "_blank");
            },
            copyCode() {
                copyToClipboard("eventCode");
            },
            setSelectedImage(file) {
                this.file = file;
            },
            inviteParticipants() {
                this.loading = true;
                var emails = [];
                this.invitedParticipants.forEach(p => {
                    if (p != '')
                        emails.push(p.email);
                });

                axios.post('/api/participants/invite/', { eventId: this.event.id, emails: emails }).then(response => {
                    this.loading = false;
                    this.invPartVisible = false;
                    this.$message({
                        message: 'Participants invited to the event.',
                        type: 'success'
                    });

                }).catch((error) => {
                    this.loading = false;
                });
            },
            submitChanges() {
                this.$refs["profileForm"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        axios.post('/api/users/createfundingsource/', this.profile).then(response => {
                            this.showACH = false;
                            this.loading = false;
                            this.getDwollaDetails();
                        }).catch((error) => {
                            this.loading = false;
                            this.errorMessage = error.response.data;
                        });
                    }
                });
            },
            verifyMicroDeposits() {
                this.$refs["profileForm2"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        axios.post('/api/users/verifymicrodeposits/', this.profile).then(response => {
                            this.profile.verifyMicroDepositsStatus = response.data;
                            this.editMode = false;
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                            this.$message({
                                message: error,
                                type: 'error'
                            });
                        });
                    }

                });
            }
        }
    }
</script>

<style scoped>
    img {
        height: 300px;
        object-fit: cover;
    }

    .fundraiser-earnings-explainer {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 15px;
        background-color: #f9f9f9;
    }

    .fundraiser-earnings-explainer ul {
        padding-left: 20px;
    }

    .fundraiser-earnings-explainer code {
        background: #eee;
        padding: 3px 6px;
        border-radius: 5px;
    }

    .fundraiser-earnings-explainer ul {
        list-style-type: disc; /* Ensures bullets are shown */
        margin-left: 20px; /* Adds left indentation */
        padding-left: 20px;
    }

    .fundraiser-earnings-explainer li {
        display: list-item; /* Ensures each <li> behaves as a block */
        margin-bottom: 5px; /* Adds spacing between items */
    }

    .participants, .donators {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        min-height: 370px;
    }

    .top-label {
        position: relative;
        top: 22px;
        background: #fff;
        left: 15px;
        font-weight: bold;
        padding: 0 5px;
    }

    .count {
        top: 25px;
        left: 15px;
    }

    .title {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        /*min-height: 250px;*/
        flex-direction: inherit;
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 370px) {
        h5 {
            font-size: 1.0rem;
        }

        h6 {
            font-size: .80rem;
            /*font-weight: bold;*/
        }
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 544px) {
        h5 {
            font-size: 1.00rem;
        }

        h6 {
            font-size: .90rem;
        }
        /*1rem = 16px*/
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {
        h5 {
            font-size: 1.25rem;
        }

        h6 {
            font-size: .95rem;
        }
        /*1rem = 16px*/
    }

    /* Large devices (desktops, 992px and up) */
    /*@media (min-width: 992px) {
        h5 {
            font-size: 1.5rem;
        }

        h6 {
            font-size: 1.0rem;
        }*/
        /*1rem = 16px*/
    /*}*/

    /* Extra large devices (large desktops, 1200px and up) */
    /*@media (min-width: 1600px) {
        h5 {
            font-size: 2.0rem;
        }

        h6 {
            font-size: 1.25rem;
        }*/
        /*1rem = 16px*/
    /*}*/

</style>

